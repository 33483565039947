import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"

import Contact from './pages/Contact'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Downloads from './pages/Downloads'

import './App.scss'

const NavBar = () => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <div className="container">
      <span className="navbar-brand mb-0 h1">ERASE-MM Community Vital Signs Project</span>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
)

const App = () => {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="container pt-3">
          <Routes>
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/single" element={<Downloads page="single" />} />
            <Route exact path="/multiple" element={<Downloads page="multiple" />} />
            <Route exact path="/state" element={<Downloads page="state" />} />
            <Route exact path="/" element={<Home/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
