import { useState } from 'react'
import { Link } from "react-router-dom"

import Search from './Search'

const Home = () => {

  return (
    <div className="Home">
      <p className="lead">
        Welcome to the ERASE-MM Community Vital Signs Project. This is a portal for state and territorial MMRCs to retrieve custom Community Vital Signs Dashboards and access technical resources and work aids to facilitate implementation.
      </p>
      <hr/>
      <p className="text-center">
        <Link className="btn btn-lg btn-outline-primary my-3 me-3" to="/single">
          Retrieve single dashboard
        </Link>
        <Link className="btn btn-lg btn-outline-primary my-3 me-3" to="/multiple">
          Retrieve multiple dashboards
        </Link>
        <Link className="btn btn-lg btn-outline-primary my-3 me-3" to="/state">
          Download State Data
        </Link>
      </p>

      <hr/>

      <p>To generate a dashboard the user must enter the year of death, the address or latitude & longitude of the decedents residence at death, and a unique ID.</p>
      <p> </p>      
      <p>This project was funded by the Centers for Disease Control and Prevention and the HHS Office of Minority Health.</p>
      <p> </p>
      <p><i>This application uses the <a href="https://geoservices.tamu.edu">Texas A&M Geocoding Services</a></i> </p>
    </div>
  )
}

export default Home;
